angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('customers.html',
    '<div class="container" ui-view>\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '            <h1 class="panel-title pull-left">Customers</h1>\n' +
    '\n' +
    '            <div class="pull-right">\n' +
    '                <a class="btn btn-xs btn-success" ui-sref=".new" tooltip="Create">\n' +
    '                    <span class="glyphicon glyphicon-plus"></span>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-model="search" placeholder="Filter" />\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive">\n' +
    '            <table class="table table-striped table-bordered">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th width="1"></th>\n' +
    '                        <th>Title</th>\n' +
    '                        <th>Domain</th>\n' +
    '                        <th width="1">Locale</th>\n' +
    '                        <th width="1">Status</th>\n' +
    '                        <th></th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, customer) in customers | search:search">\n' +
    '                        <td class="text-center">\n' +
    '                            <img height="25" class="img-rounded" ng-src="{{ customer.brand || \'/img/nobrand.png\' }}" alt="{{ customer.title }}" />\n' +
    '                        </td>\n' +
    '                        <td>{{ customer.title }}</td>\n' +
    '                        <td width="25%">\n' +
    '                            <a target="_blank" ng-href="http://{{ customer.name }}.camplan.dk">{{ customer.name }}.camplan.dk</a>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="icons text-center">{{ customer.locale | locale }}</td>\n' +
    '\n' +
    '                        <td class="text-center icons" width="1">\n' +
    '                            <span ng-if="customer.active" class="label-success label">Active</span>\n' +
    '                            <span ng-if="!customer.active" class="label-warning label">Inactive</span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="icons" width="1">\n' +
    '                            <a ui-sref=".edit({ id : customer.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                                <span class="glyphicon glyphicon-edit"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ng-if="!customer.protected" ng-click="remove(customer.id)" class="btn btn-xs btn-danger" tooltip="Delete">\n' +
    '                                <span class="glyphicon glyphicon-remove"></span>\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    <tr ng-if="!customers.length">\n' +
    '                        <td colspan="5">No Customers</td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
