angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('not-found.html',
    '<div class="login">\n' +
    '    <div class="container">\n' +
    '        <div class="row">\n' +
    '            <div class="col-lg-4 col-lg-push-4" style="margin-top:60px">\n' +
    '                <p>\n' +
    '                    <img class="img-rounded center-block" src="/img/citymedia.png" alt="CamPlan" />\n' +
    '                </p>\n' +
    '\n' +
    '                <div class="panel panel-default" style="margin-top:30px">\n' +
    '                    <div class="panel-heading">\n' +
    '                        <h3 class="panel-title">No Customer</h3>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="panel-body">\n' +
    '                        <p>Customer does not exist. Please contact us at info@camplan.dk if this is an error.</p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
