angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('profile.html',
    '<div class="container">\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading">\n' +
    '            <h1 class="panel-title">Profile</h1>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <p ng-if="updated" class="text-success alert alert-success">\n' +
    '                Your profile have been updated.\n' +
    '            </p>\n' +
    '\n' +
    '            <form ng-submit="submit()" novalidate>\n' +
    '                <div ng-include="\'_user_form.html\'"></div>\n' +
    '\n' +
    '                <button type="submit" class="btn btn-primary">Update</button>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
