angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_index_help.html',
    '<div class="modal-header">\n' +
    '    <button type="button" class="close" ng-click="$close()">&times;</button>\n' +
    '\n' +
    '    <h3 class="modal-title">Help with filtering</h3>\n' +
    '</div>\n' +
    '\n' +
    '<div class="modal-body">\n' +
    '    <p style="white-space:pre-wrap">You can use this text field to filter - or narrow down - the list below by keywords.\n' +
    '\n' +
    'You don\'t have to complete the words - simply start writing, and the filtering will kick in.The ordering of words doesn\'t matter, and neither does upper- and lowercase typing.\n' +
    '\n' +
    'You can filter on anything you see below, and using postal codes.\n' +
    '\n' +
    'For example, by writing "banner 2100" you will see all media of the type banner, in Østerbro (postal code 2100). If you add to this, "banner 2100 outdoor", you will narrow down the list further, and only see outdoor banners.\n' +
    '\n' +
    'Try it! You can\'t break anything.</p>\n' +
    '</div>\n' +
    '\n' +
    '<div class="modal-footer">\n' +
    '    <button class="btn btn-primary" type="button" ng-click="$close()">Close</button>\n' +
    '</div>\n' +
    '');
}]);
