angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_campaign_form.html',
    '<ul ng-show="errors.errors.length" class="text-danger">\n' +
    '    <li ng-repeat="error in errors.errors">{{ error }}</li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Title</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.title.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.title.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="campaign.title" class="form-control" id="title" required />\n' +
    '</div>\n' +
    '<div class="form-group">\n' +
    '    <label>Assign a special color for the campaign</label>\n' +
    '    <div class="row">\n' +
    '        <div class="col-md-3">\n' +
    '            <input type="radio" value="bg-blackout" ng-model="campaign.color" id="color-black" />\n' +
    '            <label for="color-black">Black</label>\n' +
    '        </div>\n' +
    '        <div class="col-md-3">\n' +
    '            <input type="radio" value="bg-purple" ng-model="campaign.color" id="color-purple" />\n' +
    '            <label for="color-purple">Purple</label>\n' +
    '        </div>\n' +
    '        <div class="col-md-3">\n' +
    '            <input type="radio" value="bg-blue" ng-model="campaign.color" id="color-blue" />\n' +
    '            <label for="color-blue">Blue</label>\n' +
    '        </div>\n' +
    '        <div class="col-md-3">\n' +
    '            <input type="radio" value="" ng-model="campaign.color" id="color-none" />\n' +
    '            <label for="color-none"> No color</label>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '<div class="form-group">\n' +
    '    <input type="checkbox" value="" ng-model="campaign.disabled" id="disabled" />\n' +
    '    <label for="disabled">Archived</label>\n' +
    '</div>\n' +
    '<div class="row">\n' +
    '    <div class="col-sm-2">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Display price</label>\n' +
    '            <input type="text" ng-model="campaign.displayPrice" class="form-control" />\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-1 text-center" style="font-size: 24px; font-weight: bold; line-height: 70px;">+</div>\n' +
    '    <div class="col-sm-2">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Other costs</label>\n' +
    '            <input type="text" ng-model="campaign.otherCosts" class="form-control" />\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-1 text-center" style="font-size: 24px; font-weight: bold; line-height: 70px;">=</div>\n' +
    '    <div class="col-sm-2">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Total budget</label>\n' +
    '            <input type="text" ng-model="campaign.totalBudget" class="form-control" />\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="col-sm-4">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Audience</label>\n' +
    '            <textarea ng-model="campaign.audience" class="form-control"></textarea>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-4">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Campaign goal</label>\n' +
    '            <textarea ng-model="campaign.goal" class="form-control"></textarea>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="col-sm-4">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Campaign result</label>\n' +
    '            <textarea  ng-model="campaign.result" class="form-control"></textarea>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-4">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Evaluation</label>\n' +
    '            <textarea ng-model="campaign.evaluation" class="form-control"></textarea>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="col-sm-2">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">ROI</label>\n' +
    '            <input type="text" ng-model="campaign.roi" class="form-control" />\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="col-sm-2 col-sm-push-1">\n' +
    '        <div class="form-group">\n' +
    '            <label for="disabled">Rating scale (1-10)</label>\n' +
    '            <select ng-model="campaign.rating" class="form-control">\n' +
    '                <option value="1">1</option>\n' +
    '                <option value="2">2</option>\n' +
    '                <option value="3">3</option>\n' +
    '                <option value="4">4</option>\n' +
    '                <option value="5">5</option>\n' +
    '                <option value="6">6</option>\n' +
    '                <option value="7">7</option>\n' +
    '                <option value="8">8</option>\n' +
    '                <option value="9">9</option>\n' +
    '                <option value="10">10</option>\n' +
    '            </select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="note">Note</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.note.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.note.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <textarea cols="5" ng-model="campaign.note" class="form-control"></textarea>\n' +
    '</div>\n' +
    '');
}]);
