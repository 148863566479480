angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('edit_user_customer.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Edit User</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <table class="table table-bordered">\n' +
    '        <tbody>\n' +
    '            <tr>\n' +
    '                <th width="25%">Name</th>\n' +
    '                <td>{{ userCustomer.user.name }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Email</th>\n' +
    '                <td ng-bind-html="userCustomer.user.email | linky"></td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Phone</th>\n' +
    '                <td>{{ userCustomer.user.phoneNo }}</td>\n' +
    '            </tr>\n' +
    '        <tbody>\n' +
    '    </table>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="submit()" novalidate>\n' +
    '            <div ng-include="\'_user_customer_form.html\'"></div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-primary">Update</button>\n' +
    '            <button ui-sref="user_customer" type="submit" class="btn btn-danger">Cancel</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
