angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('new_media.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">New Media</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-controller="MediaController.form">\n' +
    '        <form novalidate>\n' +
    '            <div ng-include="\'_media_form.html\'"></div>\n' +
    '\n' +
    '            <button ng-click="submit()" type="submit" class="btn btn-primary">Create</button>\n' +
    '            <button ng-click="submit(true)" type="submit" class="btn btn-primary">Create and Edit</button>\n' +
    '            <button ui-sref="media" type="submit" class="btn btn-danger">Cancel</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
