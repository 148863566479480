angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('media.html',
    '<div ng-if="media.images.length">\n' +
    '    <div class="carousel">\n' +
    '        <carousel interval="5000">\n' +
    '            <slide ng-repeat="(key, image) in media.images">\n' +
    '                <img class="carousel-slide" ng-src="{{ image.slideshow }}">\n' +
    '            </slide>\n' +
    '        </carousel>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading clearfix">\n' +
    '        <h1 class="panel-title pull-left">{{ media.title }}</h1>\n' +
    '\n' +
    '        <div class="pull-right">\n' +
    '            <a ng-href="{{ \'/media_pdf/\' + media.location.customer.id + \'_\' + media.id + \'.pdf?\' + timestamp}}" target="_blank" class="btn btn-xs btn-default" tooltip="Export">\n' +
    '                <span class="glyphicon glyphicon-export"></span>\n' +
    '            </a>\n' +
    '            <a ng-if="customer|can_edit" ui-sref="^.edit({ id : media.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                <span class="glyphicon glyphicon-edit"></span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <table class="table table-bordered">\n' +
    '        <tbody>\n' +
    '            <tr>\n' +
    '                <th width="25%">Cover</th>\n' +
    '                <td><img ng-src="{{ media.image.thumbnail || \'http://placehold.it/150x150\' }}" /></td>\n' +
    '            </tr>\n' +
    '            <tr>\n' +
    '                <th width="25%">Location</th>\n' +
    '                <td><a ui-sref="location.show({ id : media.location.id })">{{ media.location.title }}</a></td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Type</th>\n' +
    '                <td>{{ media.type | mediaType }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Category</th>\n' +
    '                <td>{{ media.category | mediaCategory }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Material</th>\n' +
    '                <td>{{ media.material }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Sales price</th>\n' +
    '                <td>{{ media.worth }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Traffic</th>\n' +
    '                <td>{{ media.traffic }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Material</th>\n' +
    '                <td>{{ media.material }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Material Information</th>\n' +
    '                <td>{{ media.materialInfo }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Deadline</th>\n' +
    '                <td>{{ media.deadline }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Delivery</th>\n' +
    '                <td>{{ media.delivery }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Finalization</th>\n' +
    '                <td>{{ media.finalization }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Count</th>\n' +
    '                <td>{{ media.count }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Period</th>\n' +
    '                <td>{{ media.period.start }} - {{ media.period.end }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Measurement</th>\n' +
    '                <td>{{ media.measurement | measurement }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Notes</th>\n' +
    '                <td>{{ media.notes }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Description</th>\n' +
    '                <td>{{ media.description }}</td>\n' +
    '            </tr>\n' +
    '        <tbody>\n' +
    '    </table>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Contacts</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!media.contacts.length">\n' +
    '        No contacts.\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive">\n' +
    '        <table ng-if="media.contacts.length" class="table table-striped table-bordered">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th>Name</th>\n' +
    '                    <th>Title</th>\n' +
    '                    <th>Email</th>\n' +
    '                    <th>Phone</th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="contact in media.contacts">\n' +
    '                    <td>{{ contact.name }}</td>\n' +
    '                    <td>{{ contact.title }}</td>\n' +
    '                    <td ng-bind-html="contact.email | linky"></td>\n' +
    '                    <td>{{ contact.phoneNo || \'None\' }}</td>\n' +
    '                </tr>\n' +
    '\n' +
    '                <tr ng-if="!media.contacts">\n' +
    '                    <td colspan="3">No Contacts</td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Files</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!media.files.length">\n' +
    '        No files.\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="media.files.length">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-2" ng-repeat="file in media.files">\n' +
    '                <div class="thumbnail">\n' +
    '                    <a ng-href="{{ file.path }}" target="_blank">\n' +
    '                        <img ng-src="{{ file.cover }}" alt="Media" />\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Images</h2>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!media.images.length">\n' +
    '        No Images\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="media.images.length">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-2" ng-repeat="(key, image) in media.images">\n' +
    '                <div class="thumbnail">\n' +
    '                    <a ng-href="{{ image.path }}" target="_blank">\n' +
    '                        <img ng-src="{{ image.thumbnail }}" alt="Media" />\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
