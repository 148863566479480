angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('users.html',
    '<div class="container" ui-view>\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '            <h1 class="panel-title pull-left">User</h1>\n' +
    '\n' +
    '            <div class="pull-right">\n' +
    '                <a class="btn btn-success btn-xs" ui-sref=".new" tooltip="Create">\n' +
    '                    <span class="glyphicon glyphicon-plus"></span>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-model="search" placeholder="Filter" />\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive">\n' +
    '            <table class="table table-striped table-bordered">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th>Email</th>\n' +
    '                        <th>Name</th>\n' +
    '                        <th>Customers</th>\n' +
    '                        <th width="1">Camplan Admin</th>\n' +
    '                        <th width="1">Protected</th>\n' +
    '                        <th width="1">Active</th>\n' +
    '                        <th></th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, user) in users|search:search">\n' +
    '                        <td><span ng-bind-html="user.email|linky"></span></td>\n' +
    '                        <td>{{ user.fullName }}</td>\n' +
    '\n' +
    '                        <td>\n' +
    '                            <a ui-sref="customer.edit({ id : userCustomer.customer.id })" ng-repeat="userCustomer in user.customers | orderBy : \'title\'">\n' +
    '                                {{ userCustomer.customer.title }}{{ $last ? \'\' : \', \' }}\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="text-center">\n' +
    '                            <span ng-class="{ \'label-success\' : user.administrator, \'label-danger\' : !user.administrator }" class="label">\n' +
    '                                &nbsp;\n' +
    '                            </span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="text-center">\n' +
    '                            <span ng-class="{ \'label-success\' : user.protected, \'label-danger\' : !user.protected }" class="label">\n' +
    '                                &nbsp;\n' +
    '                            </span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="text-center">\n' +
    '                            <span ng-class="{ \'label-success\' : user.active, \'label-danger\' : !user.active }" class="label">\n' +
    '                                &nbsp;\n' +
    '                            </span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="1" style="white-space:nowrap">\n' +
    '                            <a ui-sref=".edit({ id : user.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                                <span class="glyphicon glyphicon-edit"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ng-if="user.id !== auth.id && !user.protected" ng-click="remove(user.id)" class="btn btn-xs btn-danger" tooltip="Delete">\n' +
    '                                <span class="glyphicon glyphicon-remove"></span>\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    <tr ng-show="!users.length">\n' +
    '                        <td colspan="5">No Users</td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
