angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('login.html',
    '<div class="login">\n' +
    '    <div class="container">\n' +
    '        <div class="row">\n' +
    '            <div class="col-lg-4 col-lg-push-4" style="margin-top:60px">\n' +
    '                <p>\n' +
    '                    <img class="img-rounded center-block" ng-src="{{ customer.brand || \'/img/nobrand.png\' }}" alt="{{ customer.title }}" />\n' +
    '                </p>\n' +
    '\n' +
    '                <div class="panel panel-default" style="margin-top:30px">\n' +
    '                    <div class="panel-heading">\n' +
    '                        <h3 class="panel-title">Please Login</h3>\n' +
    '                    </div>\n' +
    '\n' +
    '                    <div class="panel-body">\n' +
    '                        <form ng-submit="login()">\n' +
    '                            <div class="form-group">\n' +
    '                                <label for="email">Email address:</label>\n' +
    '\n' +
    '                                <ul ng-show="error.length">\n' +
    '                                    <li class="text-danger">{{ error }}</p>\n' +
    '                                </ul>\n' +
    '\n' +
    '                                <div class="input-group">\n' +
    '                                    <span class="input-group-addon">\n' +
    '                                        <span class="glyphicon glyphicon-envelope"></span>\n' +
    '                                    </span>\n' +
    '\n' +
    '                                    <input type="email" ng-model="credentials.username" class="form-control" id="email" required>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="form-group">\n' +
    '                                <label for="pwd">Password:</label>\n' +
    '\n' +
    '                                <div class="input-group">\n' +
    '                                    <span class="input-group-addon">\n' +
    '                                        <span class="glyphicon glyphicon-lock"></span>\n' +
    '                                    </span>\n' +
    '\n' +
    '                                    <input type="password" ng-model="credentials.password" class="form-control" id="pwd" required>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <p>\n' +
    '                                <button type="submit" class="btn btn-success btn-block">Login</button>\n' +
    '                            </p>\n' +
    '                        </form>\n' +
    '\n' +
    '                        <p>\n' +
    '                            <a target="_self" href="mailto:forgotpassword@camplan.dk">Forgot password?</a>\n' +
    '                        </p>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
