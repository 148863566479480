angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('duplicate_media.html',
    '<form name="duplicateMedia">\n' +
    '    <div class="modal-header">\n' +
    '        <button type="button" class="close" ng-click="$close()">&times;</button>\n' +
    '\n' +
    '        <h3 class="modal-title">Duplicate media</h3>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="modal-body">\n' +
    '        <div ng-if="error">\n' +
    '            <span>{{error}}</span>\n' +
    '        </div>\n' +
    '        <div class="form-group">\n' +
    '            <ui-select ng-model="selectedLocation.selected" id="test" name="test" ng-disabled="loading" search-enabled="true">\n' +
    '                <ui-select-match placeholder="Choose mediagroup">\n' +
    '                    <span ng-bind="$select.selected.title"></span>\n' +
    '                </ui-select-match>\n' +
    '                <ui-select-choices ng-disable="true" repeat="location in (locations | filter: $select.search) track by $index">\n' +
    '                    <span ng-bind="location.title"></span>\n' +
    '                </ui-select-choices>\n' +
    '            </ui-select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="modal-footer">\n' +
    '        <button class="btn btn-danger" type="button" ng-click="$close()" ng-disabled="loading">Close</button>\n' +
    '        <button class="btn btn-primary" type="button" ng-click="submit($close, selectedLocation)" ng-disabled="loading">Duplicate</button>\n' +
    '    </div>\n' +
    '</form>');
}]);
