angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('edit_campaign.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Edit Campaign</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="submit()" novalidate>\n' +
    '            <div ng-include="\'_campaign_form.html\'"></div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-primary">Update</button>\n' +
    '            <button ui-sref="campaign" type="submit" class="btn btn-danger">Cancel</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">PDF\'s</h2>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!campaign.files.length">\n' +
    '        No files\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="campaign.files.length">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-2" ng-repeat="(key, file) in campaign.files">\n' +
    '                <div class="thumbnail">\n' +
    '                    <a ng-href="{{ file.path }}" target="_blank">\n' +
    '                        <img ng-src="{{ file.cover }}" alt="Media" />\n' +
    '                        {{ file.name }}\n' +
    '                    </a>\n' +
    '                    <div class="btn-group btn-group-xs btn-group-justified">\n' +
    '                        <a ng-click="removeFile(key)" class="btn btn-danger" role="button">Remove</a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-footer clearfix panel-footer-form">\n' +
    '        <div class="form-inline pull-right">\n' +
    '            <div class="form-inline pull-right">\n' +
    '                <label for="file" class="sr-only">File</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <input class="input-sm form-control" onchange="angular.element(this).scope().fileSelected(this)" type="file" id="file" />\n' +
    '\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <a ng-click="upload()" class="btn btn-success btn-sm">\n' +
    '                            <span class="glyphicon glyphicon-upload"></span>\n' +
    '                        </a>\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Images</h2>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!campaign.images.length">\n' +
    '        No Images\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="campaign.images.length">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-2" ng-repeat="(key, image) in campaign.images">\n' +
    '                <div class="thumbnail">\n' +
    '                    <a ng-href="{{ image.path }}" target="_blank">\n' +
    '                        <img ng-src="{{ image.thumbnail }}" alt="Media" />\n' +
    '                    </a>\n' +
    '\n' +
    '                    <div class="btn-group btn-group-xs btn-group-justified">\n' +
    '                        <a ng-click="removeImage(key)" class="btn btn-danger" role="button">Remove</a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-footer clearfix panel-footer-form">\n' +
    '        <div class="form-inline pull-right">\n' +
    '            <div class="form-group">\n' +
    '                <label for="file" class="sr-only">Image</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <input class="input-sm form-control" onchange="angular.element(this).scope().imageSelected(this)" type="file" id="file" />\n' +
    '\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <a ng-click="coverImage()" class="btn btn-success btn-sm">\n' +
    '                            <span class="glyphicon glyphicon-upload"></span>\n' +
    '                        </a>\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Links</h2>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!campaign.links.length && !addLinkActive">\n' +
    '        No Links\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-12" ng-repeat="(key, value) in campaign.links">\n' +
    '                <span><a href="{{value.url}}" target="_blank">{{ value.name }} (<span>{{value.url}}</span>)</a></span> <a ng-click="removeLink(value)" class="glyphicon glyphicon-remove"></a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div class="row" ng-if="addLinkActive">\n' +
    '            <div class="col-sm-12" style="border-top: 1px solid #ddd; padding-top: 20px; margin-top: 10px;">\n' +
    '                <div class="form-group col-xs-3">\n' +
    '                    <label>\n' +
    '                        Title\n' +
    '                        <input type="text" class="form-control" ng-model="link.name" />\n' +
    '                    </label>\n' +
    '                </div>\n' +
    '                <div class="form-group col-xs-3">\n' +
    '                    <label>\n' +
    '                        URL\n' +
    '                        <input type="text" class="form-control" placeholder="e.g. http://google.com" ng-model="link.url" />\n' +
    '                    </label>\n' +
    '                </div>\n' +
    '                <div class="form-group col-xs-3">&nbsp;<br />\n' +
    '                    <a class="btn btn-default" ng-click="addLink()">Add link</a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-footer clearfix panel-footer-form">\n' +
    '        <div class="form-inline pull-right">\n' +
    '            <div class="form-group">\n' +
    '                <label for="file" class="sr-only">Image</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <a ng-click="toggleAddLink()" class="btn btn-success btn-sm">Opret link</a>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
