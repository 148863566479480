angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_user_form.html',
    '<ul ng-show="errors.errors.length" class="text-danger">\n' +
    '    <li ng-repeat="error in errors.errors">{{ error }}</li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Name</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.fullName.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.fullName.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="user.fullName" class="form-control" id="title" required>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Phone</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.phoneNo.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.phoneNo.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="user.phoneNo" class="form-control" id="title">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Email</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.email.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.email.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="email" ng-model="user.email" class="form-control" id="title" required>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-sm-6">\n' +
    '        <label for="title">Password</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.password.children.first.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.password.children.first.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="password" ng-model="password.first" class="form-control" id="title">\n' +
    '        <p class="help-block">Leave blank if you do not wish to the change password.</p>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-sm-6">\n' +
    '        <label for="title">Repeat Password</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.password.children.second.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.password.children.second.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="password" ng-model="password.second" class="form-control" id="title">\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div ng-if="!$state.includes(\'profile\') && auth.administrator">\n' +
    '    <div class="form-group">\n' +
    '        <label>Administrative</label>\n' +
    '\n' +
    '        <div class="checkbox">\n' +
    '            <label>\n' +
    '                <input type="checkbox" name="active" ng-model="user.active">\n' +
    '                <span>Active</span>\n' +
    '                <p class="help-block">User that is not active, cannot login to the system.</p>\n' +
    '            </label>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="checkbox">\n' +
    '            <label>\n' +
    '                <input type="checkbox" name="administrator" ng-model="user.administrator">\n' +
    '                <span>Camplan Administrator</span>\n' +
    '                <p class="help-block">Camplan Administrators can do everything! With great power comes responsibility.</p>\n' +
    '            </label>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="checkbox">\n' +
    '            <label>\n' +
    '                <input type="checkbox" name="protected" ng-model="user.protected">\n' +
    '                <span>Protected</span>\n' +
    '                <p class="help-block">Protected users cannot be deleted. Not even by an Administrator.</p>\n' +
    '            </label>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
