angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_customer_form.html',
    '<ul ng-show="errors.errors.length" class="text-danger">\n' +
    '    <li ng-repeat="error in errors.errors">{{ error }}</li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Title</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.title.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.title.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="customer.title" required class="form-control" id="title" placeholder="Enter title">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="streetName">Domain</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.name.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.name.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="customer.name" required class="form-control" id="streetName">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="locale">Locale</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.locale.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.locale.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <select class="form-control" ng-model="customer.locale" ng-options="code as locale for (code, locale) in Locales">\n' +
    '        <option value=""></option>\n' +
    '    </select>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="theme">Theme</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.theme.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.theme.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <theme-select theme="customer.theme"></theme-select>\n' +
    '    <!--select class="form-control" ng-model="customer.theme" ng-options="theme as theme for theme in themes">\n' +
    '    </select-->\n' +
    '\n' +
    '    <span class="help-block">Theme controls the colors.</span>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label>Utilities</label>\n' +
    '\n' +
    '    <div class="checkbox">\n' +
    '        <label>\n' +
    '            <input type="checkbox" name="active" ng-model="customer.active" />\n' +
    '            <span>Active</span>\n' +
    '\n' +
    '            <p class="help-block">If a customer is inactive, then it is not possible to login for that Customer.</p>\n' +
    '        </label>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="checkbox">\n' +
    '        <label>\n' +
    '            <input type="checkbox" name="protected" ng-model="customer.protected" />\n' +
    '            <span>Protected</span>\n' +
    '\n' +
    '            <p class="help-block">Protected customers cannot be deleted. Not even by an Administrator.</p>\n' +
    '        </label>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
