angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('new_customer.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">New Customer</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="submit()" novalidate>\n' +
    '            <div ng-include="\'_customer_form.html\'"></div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-primary">Create</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
