angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_user_customer_form.html',
    '<ul ng-show="errors.errors.length" class="text-danger">\n' +
    '    <li ng-repeat="error in errors.errors">{{ error }}</li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="locations">Locations</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.locations.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.locations.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="col-sm-3" ng-repeat="location in locations|orderBy:\'title\'">\n' +
    '            <div class="checkbox">\n' +
    '                <label>\n' +
    '                    <input type="checkbox" ng-click="click(location)" value="{{ location.id }}" ng-checked="userCustomerLocations.indexOf(location.id) >= 0">\n' +
    '                    <span>{{ location.title }}</span>\n' +
    '                </label>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="locations">Administrative</label>\n' +
    '\n' +
    '    <div class="checkbox">\n' +
    '        <label>\n' +
    '            <input type="checkbox" ng-model="userCustomer.editor" ng-disabled="userCustomer.user.administrator">\n' +
    '            <span>Booker</span>\n' +
    '\n' +
    '            <p class="help-block">\n' +
    '                Bookers can add, remove and edit Locations, Media.\n' +
    '            </p>\n' +
    '        </label>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="checkbox">\n' +
    '        <label>\n' +
    '            <input type="checkbox" ng-model="userCustomer.administrator" ng-disabled="userCustomer.user.administrator">\n' +
    '            <span>Account Administrator</span>\n' +
    '\n' +
    '            <p class="help-block">\n' +
    '                Account Administrators can see ALL locations, media, campaigns. Also they can add/remove users.\n' +
    '            </p>\n' +
    '        </label>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
