angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('edit_user.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Edit User</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="submit()">\n' +
    '            <div ng-include="\'_user_form.html\'"></div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-primary">Update</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Customers</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive">\n' +
    '        <table class="table table-striped table-bordered">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th>Name</th>\n' +
    '                    <th>Locations</th>\n' +
    '                    <th width="1">Booker</th>\n' +
    '                    <th></th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="(key, userCustomer) in user.customers">\n' +
    '                    <td><a ui-sref="customer.edit({ id : userCustomer.customer.id })">{{ userCustomer.customer.title }}</a></td>\n' +
    '                    <td>\n' +
    '                        <a ng-repeat="location in userCustomer.locations | orderBy:\'title\'" ui-sref="location.show({ id : location.id })">\n' +
    '                            {{ location.title }}{{ $last ? \'\' : \', \' }}\n' +
    '                        </a>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td class="text-center" width="1">\n' +
    '                        <span ng-class="{ \'label-success\' : userCustomer.editor, \'label-danger\' : !userCustomer.editor }" class="label">\n' +
    '                            &nbsp;\n' +
    '                        </span>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td width="1" class="icons">\n' +
    '                        <a ng-click="remove(key)" class="btn btn-xs btn-danger" tooltip="Delete">\n' +
    '                            <span class="glyphicon glyphicon-remove"></span>\n' +
    '                        </a>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '\n' +
    '                <tr ng-if="!user.customers.length">\n' +
    '                    <td colspan="5">No Customers.</td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-footer clearfix panel-footer-form">\n' +
    '        <form ng-submit="add()" class="form-inline pull-right">\n' +
    '            <div class="form-group">\n' +
    '                <div class="checkbox">\n' +
    '                    <label>\n' +
    '                        <input type="checkbox" name="active" ng-model="userCustomer.editor">\n' +
    '                        <span>Booker</span>\n' +
    '                    </label>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="form-group">\n' +
    '                <label class="sr-only hidden">Customer</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <select class="form-control input-sm" ng-model="userCustomer.customer" ng-options="customer.id as customer.title for customer in customers">\n' +
    '                        <option value="">Choose Customer</option>\n' +
    '                    </select>\n' +
    '\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <button type="submit" class="btn btn-success btn-sm" tooltip="Add">\n' +
    '                            <span class="glyphicon glyphicon-check"></span>\n' +
    '                        </button>\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
