angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('edit_media.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Edit media</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-controller="MediaController.form">\n' +
    '        <form ng-submit="submit()" novalidate>\n' +
    '            <div ng-include="\'_media_form.html\'"></div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-primary">Update</button>\n' +
    '            <button ui-sref="media" type="submit" class="btn btn-danger">Cancel</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">PDF\'s</h2>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!media.files">\n' +
    '        No files\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="media.files.length">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-2" ng-repeat="(key, file) in media.files">\n' +
    '                <div class="thumbnail">\n' +
    '                    <a ng-href="{{ file.path }}" target="_blank">\n' +
    '                        <img ng-src="{{ file.cover }}" alt="Media" />\n' +
    '                        {{ file.name }}\n' +
    '                    </a>\n' +
    '                    <div class="btn-group btn-group-xs btn-group-justified">\n' +
    '                        <a ng-click="removeFile(key)" class="btn btn-danger" role="button">Remove</a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-footer clearfix panel-footer-form">\n' +
    '        <form ng-submit="upload()" class="form-inline pull-right">\n' +
    '            <div class="form-group">\n' +
    '                <label for="file" class="sr-only">File</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <input class="input-sm form-control" onchange="angular.element(this).scope().file = this.files[0]" type="file" id="file" />\n' +
    '\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <button type="submit" class="btn btn-success btn-sm">\n' +
    '                            <span class="glyphicon glyphicon-upload"></span>\n' +
    '                        </button>\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Images</h2>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!media.images.length">\n' +
    '        No Images\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="media.images.length">\n' +
    '        <div class="row">\n' +
    '            <div class="col-sm-2" ng-repeat="(key, image) in media.images">\n' +
    '                <div class="thumbnail">\n' +
    '                    <a ng-href="{{ image.path }}" target="_blank">\n' +
    '                        <img ng-src="{{ image.thumbnail }}" alt="Media" />\n' +
    '                    </a>\n' +
    '\n' +
    '                    <div class="btn-group btn-group-xs btn-group-justified">\n' +
    '                        <a ng-show="image.id != media.image.id" ng-click="primaryImage(key)" class="btn btn-success" role="button">Primary</a>\n' +
    '                        <a ng-click="removeImage(key)" class="btn btn-danger" role="button">Remove</a>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-footer clearfix panel-footer-form">\n' +
    '        <form ng-submit="coverImage()" class="form-inline pull-right">\n' +
    '            <div class="form-group">\n' +
    '                <label for="file" class="sr-only">Image</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <input class="input-sm form-control" onchange="angular.element(this).scope().image = this.files[0]" type="file" id="file" />\n' +
    '\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <button type="submit" class="btn btn-success btn-sm">\n' +
    '                            <span class="glyphicon glyphicon-upload"></span>\n' +
    '                        </button>\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
