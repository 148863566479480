angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('user_customers.html',
    '<div class="container" ui-view>\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '            <h1 class="panel-title pull-left">User</h1>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-model="search" placeholder="Filter" />\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive">\n' +
    '            <table class="table table-striped table-bordered">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th>Name</th>\n' +
    '                        <th>Email</th>\n' +
    '                        <th>Locations</th>\n' +
    '                        <th width="1">Booker</th>\n' +
    '                        <th width="1">Account Admin</th>\n' +
    '                        <th width="1"></th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, userCustomer) in userCustomers | search:search">\n' +
    '                        <td><a ui-sref=".show({ id : userCustomer.id })">{{ userCustomer.user.name }}</a></td>\n' +
    '                        <td><span ng-bind-html="userCustomer.user.email|linky"></span></td>\n' +
    '                        <td>\n' +
    '                            <a ng-repeat="location in userCustomer.locations | orderBy:\'title\'" ui-sref="location.show({ id : location.id })">\n' +
    '                                {{ location.title }}{{ $last ? \'\' : \', \' }}\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="text-center" width="1">\n' +
    '                            <span ng-class="{ \'label-success\' : userCustomer.editor, \'label-danger\' : !userCustomer.editor }" class="label">\n' +
    '                                &nbsp;\n' +
    '                            </span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="text-center" width="1">\n' +
    '                            <span ng-class="{ \'label-success\' : userCustomer.administrator, \'label-danger\' : !userCustomer.administrator }" class="label">\n' +
    '                                &nbsp;\n' +
    '                            </span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="1" class="icons">\n' +
    '                            <a ui-sref=".edit({ id : userCustomer.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                                <span class="glyphicon glyphicon-edit"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ng-click="remove(userCustomer.id)" class="btn btn-xs btn-danger" tooltip="Delete">\n' +
    '                                <span class="glyphicon glyphicon-remove"></span>\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                    <tr ng-if="!userCustomers.length">\n' +
    '                        <td colspan="5">No Users</td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-footer clearfix panel-footer-form">\n' +
    '            <form ng-submit="invite()" class="form-inline pull-right" novalidate>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <label for="file" class="sr-only">Email</label>\n' +
    '\n' +
    '                    <span class="pull-left small text-danger">{{ error }}</span>\n' +
    '\n' +
    '                    <div class="input-group">\n' +
    '                        <input class="input-sm form-control" ng-model="$parent.email" type="email" placeholder="Enter email" />\n' +
    '\n' +
    '                        <span class="input-group-btn">\n' +
    '                            <button type="submit" class="btn btn-success btn-sm" tooltip="Add">\n' +
    '                                <span class="glyphicon glyphicon-plus"></span>\n' +
    '                            </button>\n' +
    '                        </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
