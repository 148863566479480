angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('admin.html',
    '<div class="navbar navbar-fixed-top navbar-default navbar-condensed navbar-secondary">\n' +
    '    <div class="container">\n' +
    '        <ul class="nav navbar-nav">\n' +
    '            <li ui-sref-active="active"><a ui-sref="customer">Customers</a></li>\n' +
    '            <li ui-sref-active="active"><a ui-sref="user">Users</a></li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="has-secondary-navbar" ui-view></div>\n' +
    '');
}]);
