angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('campaign.html',
    '<div class="panel panel-default" ng-class="!campaign.disabled || \'panel-info\'">\n' +
    '    <div class="panel-heading clearfix">\n' +
    '        <h1 class="panel-title pull-left">{{ campaign.title }}</h1>\n' +
    '\n' +
    '        <div ng-show="customer|can_book" class="pull-right">\n' +
    '            <a ui-sref="^.edit({ id : campaign.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                <span class="glyphicon glyphicon-edit"></span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <table class="table table-bordered">\n' +
    '        <tbody>\n' +
    '            <tr>\n' +
    '                <th width="25%">Title</th>\n' +
    '                <td>{{ campaign.title }} <span ng-show="campaign.disabled">(Archived)</span></td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th colspan="2">Note</th>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr ng-switch="campaign.note.length > 0">\n' +
    '                <td ng-switch-when="true" colspan="2">\n' +
    '                    {{ campaign.note }}\n' +
    '                </td>\n' +
    '\n' +
    '                <td ng-switch-when="false" colspan="2" class="text-muted">\n' +
    '                    No Note.\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '        <tbody>\n' +
    '    </table>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Bookings</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="false">\n' +
    '            <div class="form-group">\n' +
    '                <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                <input type="search" class="form-control" ng-model="search" placeholder="Filter" />\n' +
    '            </div>\n' +
    '            <!-- Kept as it may be relevant later\n' +
    '            <div class="form-group">\n' +
    '                <button class="btn btn-danger" ng-click="deleteSelected()">Delete selected</button>\n' +
    '            </div>\n' +
    '            -->\n' +
    '            <button ng-click="deleteSelected()" class="btn btn-xs btn-danger" tooltip="{{ \'Delete selected\' }}">\n' +
    '                <span class="glyphicon glyphicon-remove"></span>\n' +
    '            </button>\n' +
    '\n' +
    '            <button ng-click="saveSelectedFiles()" class="btn btn-xs btn-primary">Download selected PDFs</button>\n' +
    '\n' +
    '        </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive">\n' +
    '        <table class="table table-bordered table-striped">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th width="40">\n' +
    '                        <input type="checkbox" ng-change="changeAll()" ng-model="selectAll" />\n' +
    '                    </th>\n' +
    '                    <th width="1">Medium</th>\n' +
    '                    <th>Location</th>\n' +
    '                    <th>Contact</th>\n' +
    '                    <th>Confirmed</th>\n' +
    '                    <th width="1">Weeks</th>\n' +
    '                    <th colspan="2" width="1">Period</th>\n' +
    '                    <th ng-if="customer|can_book"></th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '\n' +
    '            <tbody>\n' +
    '                <tr ng-class="{cardinality: (booking.cardinality > 0)}" ng-repeat="(key, booking) in bookingsArray|search:search">\n' +
    '                    <td>\n' +
    '                        <input type="checkbox" ng-model="selectedBookings[booking.id]" />\n' +
    '                    </td>\n' +
    '                    <td class="icons">\n' +
    '                        <a ui-sref="media.show({ id : booking.m })">\n' +
    '                            {{ media[booking.m].title }}\n' +
    '                        </a>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td>\n' +
    '                        <a ui-sref="location.show({ id : media[booking.m].location.id })">\n' +
    '                            {{ media[booking.m].location.title }}\n' +
    '                        </a>\n' +
    '                    </td>\n' +
    '                    <td>\n' +
    '                        {{ booking.u.n }}\n' +
    '                        <a href="mailto:{{ booking.u.e }}">{{ booking.u.e }}</a>\n' +
    '                        {{ booking.u.p }}\n' +
    '                    </td>\n' +
    '                    <td width="1" class="icons text-center">\n' +
    '                        <span ng-if="bookings[booking.id].co" class="label label-success">Yes</span>\n' +
    '                        <span ng-if="!bookings[booking.id].co" class="label label-warning">No</span>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td width="1" class="icons text-center">\n' +
    '                        {{ booking.p.start|moment:\'W\' }} &rarr; {{ booking.p.end|moment:\'W\' }}\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td width="95" class="icons">\n' +
    '                        <input ng-if="customer|can_book" type="text" class="form-control input-xs" ng-click="oldDate = bookings[booking.id].p.start; datepickers[key].start = !datepickers[key].start" datepicker-popup is-open="datepickers[key].start"  ng-model="bookings[booking.id].p.start" ng-change="editDate(key, oldDate)" />\n' +
    '                        <span ng-if="!(customer|can_book)">{{ booking.p.start }}</span>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td width="95" class="icons">\n' +
    '                        <input ng-if="customer|can_book" type="text" class="form-control input-xs" min-date="bookings[booking.id].p.start" ng-click="datepickers[key].end = !datepickers[key].end" datepicker-popup is-open="datepickers[key].end" ng-model="bookings[booking.id].p.end" />\n' +
    '                        <span ng-if="!(customer|can_book)">{{ booking.p.end }}</span>\n' +
    '                    </td>\n' +
    '\n' +
    '                    <td ng-if="customer|can_book" class="icons" width="1">\n' +
    '                        <button ng-click="save(booking.id)" class="btn btn-xs btn-success" tooltip="{{ \'Save\' }}">\n' +
    '                            <span class="glyphicon glyphicon-check"></span>\n' +
    '                        </button>\n' +
    '\n' +
    '                        <button ng-click="remove(booking.id)" class="btn btn-xs btn-danger" tooltip="{{ \'Delete\' }}">\n' +
    '                            <span class="glyphicon glyphicon-remove"></span>\n' +
    '                        </button>\n' +
    '\n' +
    '                        <button ng-click="confirm(booking.id)" class="btn btn-xs btn-default" tooltip="{{ \'Toggle Confirmation\' }}">\n' +
    '                            <span class="glyphicon glyphicon-ok"></span>\n' +
    '                        </button>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="(bookingCount < 1)" class="panel-body">\n' +
    '        No bookings.\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
