angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('medias.html',
    '<div class="container" ui-view>\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '            <h1 class="panel-title pull-left">Media</h1>\n' +
    '\n' +
    '            <div ng-if="customer|can_edit" class="pull-right">\n' +
    '                <a class="btn btn-success btn-xs" ui-sref="media.new" tooltip="Create">\n' +
    '                    <span class="glyphicon glyphicon-plus"></span>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-model="search" placeholder="Filter" />\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive">\n' +
    '            <table class="table table-striped table-bordered">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th colspan="2">Title</th>\n' +
    '                        <th>Location</th>\n' +
    '                        <th>Category</th>\n' +
    '                        <th>Type</th>\n' +
    '                        <th>Format</th>\n' +
    '                        <th width="1">Series</th>\n' +
    '                        <th ng-if="customer|can_edit"></th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, media) in medias | search:search">\n' +
    '                        <td width="40">\n' +
    '                            <img ng-src="{{ media.image.thumbnail || \'http://placehold.it/40x40\' }}" width="40" />\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td><a ui-sref="media.show({ id : media.id })">{{ media.title }}</a></td>\n' +
    '                        <td><a ui-sref="location.show({ id : media.location.id })">{{ media.location.title }}</a></td>\n' +
    '\n' +
    '                        <td>{{ media.category | mediaCategory }}</td>\n' +
    '                        <td>{{ media.type | mediaType }}</td>\n' +
    '                        <td>{{ media.measurement | measurement }}</td>\n' +
    '\n' +
    '                        <td class="text-center">\n' +
    '                            <span ng-class="{ \'label-success\' : media.series, \'label-danger\' : !media.series }" class="label">\n' +
    '                                {{ media.series ? \'Yes\' : \'No\' }}\n' +
    '                            </span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td ng-if="customer|can_edit" class="icons" width="1">\n' +
    '                            <a ng-click="duplicateMedia(media)" class="btn btn-xs btn-info" tooltip="Copy">\n' +
    '                                <span class="glyphicon glyphicon-duplicate"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ui-sref="media.edit({ id : media.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                                <span class="glyphicon glyphicon-edit"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ng-click="remove(media.id)" class="btn btn-xs btn-danger" tooltip="Delete">\n' +
    '                                <span class="glyphicon glyphicon-remove"></span>\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body" ng-show="!medias.length">\n' +
    '            No media.\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
