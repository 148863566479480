angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('locations.html',
    '<div class="container" ui-view>\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '            <h1 class="panel-title pull-left">Locations</h1>\n' +
    '\n' +
    '            <div ng-if="customer|can_edit" class="pull-right">\n' +
    '                <a class="btn btn-success btn-xs" ui-sref=".new" tooltip="Create">\n' +
    '                    <span class="glyphicon glyphicon-plus"></span>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-model="search" placeholder="Filter" />\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive">\n' +
    '            <table class="table table-striped table-bordered">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th>Name</th>\n' +
    '                        <th>Address</th>\n' +
    '                        <th>Primary Contact</td>\n' +
    '                        <th>Secondary Contact</th>\n' +
    '                        <th ng-if="customer|can_edit"></th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, location) in locations | search:search">\n' +
    '                        <td><a ui-sref=".show({ id : location.id })">{{ location.title }}</a></td>\n' +
    '                        <td>\n' +
    '                            {{ location.streetName }} {{ location.houseNo }},<br />\n' +
    '                            {{ location.city }} {{ location.postalCode }}, {{ location.country | country }}\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td ng-repeat="contact in location.contacts|slice:0:2">\n' +
    '                            {{ contact.title }} {{ contact.name }}<br />\n' +
    '                            {{ contact.phone }}<br />\n' +
    '                            <span ng-bind-html="contact.email|linky"></span>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td ng-if="location.contacts.length < 1"></td>\n' +
    '                        <td ng-if="location.contacts.length < 2"></td>\n' +
    '\n' +
    '                        <td ng-if="customer|can_edit" class="icons" width="1">\n' +
    '\n' +
    '\n' +
    '                            <a ui-sref=".edit({ id : location.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                                <span class="glyphicon glyphicon-edit"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ng-click="remove(location.id)" class="btn btn-xs btn-danger" tooltip="Delete">\n' +
    '                                <span class="glyphicon glyphicon-remove"></span>\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-if="!locations.length" class="panel-body">\n' +
    '            No locations.\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
