angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('campaigns.html',
    '<div class="container" ui-view>\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading clearfix">\n' +
    '            <h1 class="panel-title pull-left">Campaigns</h1>\n' +
    '\n' +
    '            <div ng-if="customer|can_book" class="pull-right">\n' +
    '                <a class="btn btn-success btn-xs" ui-sref=".new" tooltip="{{ \'Create\' }}">\n' +
    '                    <span class="glyphicon glyphicon-plus"></span>\n' +
    '                </a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '                    <div class="input-group">\n' +
    '                        <input type="search" class="form-control" ng-model="search" placeholder="Filter" aria-describedby="show-archived" />\n' +
    '                        <span id="show-archived" class="input-group-btn">\n' +
    '                            <button ng-click="(showArchived = true)" ng-show="!showArchived" class="btn btn-default">\n' +
    '                                Show archived\n' +
    '                            </button>\n' +
    '                            <button ng-click="(showArchived = false)" ng-show="showArchived" class="btn btn-default">\n' +
    '                                Show active\n' +
    '                            </button>\n' +
    '                        </span>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive">\n' +
    '            <table class="table table-striped table-bordered">\n' +
    '                <thead>\n' +
    '                    <tr>\n' +
    '                        <th>Name</th>\n' +
    '                        <th>State</th>\n' +
    '                        <th>Weeks</th>\n' +
    '                        <th colspan="2">Period</th>\n' +
    '                        <th ng-if="customer|can_book"></th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody>\n' +
    '                    <tr ng-repeat="(key, campaign) in campaigns | search:search | isArchived:showArchived">\n' +
    '                        <td>\n' +
    '                            <a ui-sref=".show({ id : campaign.id })">{{ campaign.title }}</a>\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="1" class="icons text-center">\n' +
    '                            {{  campaign.state }}\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td width="1" class="icons text-center">\n' +
    '                            {{ campaign.period.start | moment:\'W\' }} &rarr; {{ campaign.period.end | moment:\'W\' }}\n' +
    '                        </td>\n' +
    '\n' +
    '                        <td class="icons" width="1">{{ campaign.period.start }}</td>\n' +
    '                        <td class="icons" width="1">{{ campaign.period.end }}</td>\n' +
    '\n' +
    '                        <td ng-if="customer|can_book" class="icons" width="1">\n' +
    '                            <a ui-sref=".edit({ id : campaign.id })" class="btn btn-xs btn-info" tooltip="{{ \'Edit\' }}">\n' +
    '                                <span class="glyphicon glyphicon-edit"></span>\n' +
    '                            </a>\n' +
    '\n' +
    '                            <a ng-click="remove(campaign.id)" class="btn btn-xs btn-danger" tooltip="{{ \'Delete\' }}">\n' +
    '                                <span class="glyphicon glyphicon-remove"></span>\n' +
    '                            </a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '\n' +
    '        <div ng-show="!campaigns.length" class="panel-body">\n' +
    '            No campaigns.\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
