angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('yearview.html',
    '<div class="container-fluid">\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading">\n' +
    '            <h1 class="panel-title">Year View</h1>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-keyup="saveSearch()" ng-model="search" placeholder="Filter" />\n' +
    '\n' +
    '                    <button class="btn btn-link inner-addon" ng-click="help()" tooltip="{{ \'Help\' }}">\n' +
    '                        <span class="glyphicon glyphicon-question-sign"></span>\n' +
    '                    </button>\n' +
    '                </div>\n' +
    '\n' +
    '                <fieldset>\n' +
    '                    <legend>\n' +
    '                        <button ng-click="advanced = !advanced" class="btn btn-link">Show/Hide advanced filtering</button>\n' +
    '                    </legend>\n' +
    '\n' +
    '                    <div ng-hide="!advanced" class="clearfix">\n' +
    '                        <div class="row">\n' +
    '                            <div class="form-group col-md-2">\n' +
    '                                <label>Types</label>\n' +
    '\n' +
    '                                <div class="checkbox" ng-repeat="(name, title) in TYPES" >\n' +
    '                                    <label>\n' +
    '                                        <input type="checkbox" value="{{ name }}" ng-click="toggle(filters.types, name)" ng-checked="checkFilter(filters.types, name)"> {{ ::title }}\n' +
    '                                    </label>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="form-group col-md-2">\n' +
    '                                <label>Categories</label>\n' +
    '\n' +
    '                                <div class="checkbox" ng-repeat="(name, title) in CATEGORIES" >\n' +
    '                                    <label>\n' +
    '                                        <input type="checkbox" value="{{ name }}" ng-click="toggle(filters.categories, name)" ng-checked="checkFilter(filters.categories, name)"> {{ ::title }}\n' +
    '                                    </label>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="form-group col-md-2">\n' +
    '                                <label>Locations</label>\n' +
    '                                <div class="checkbox" ng-repeat="location in locations">\n' +
    '                                    <label>\n' +
    '                                        <input type="checkbox" value="{{ location.id }}" ng-click="toggle(filters.locations, location.id)" ng-checked="checkFilter(filters.locations, location.id)"> {{ location.title }}\n' +
    '                                    </label>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="col-md-4">\n' +
    '                                <div class="row form-group">\n' +
    '                                    <label class="col-sm-12">Media</label>\n' +
    '\n' +
    '                                    <div class="col-sm-6">\n' +
    '                                        <div class="checkbox" ng-repeat="mediumTitle in facetMedia|slice:0:facetMedia.length/2">\n' +
    '                                            <label>\n' +
    '                                                <input type="checkbox" value="{{ mediumTitle }}" ng-click="toggle(filters.media, mediumTitle)" ng-checked="checkFilter(filters.media, mediumTitle)"> {{ ::mediumTitle }}\n' +
    '                                            </label>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '\n' +
    '                                    <div class="col-sm-6">\n' +
    '                                        <div class="checkbox" ng-repeat="mediumTitle in facetMedia|slice:facetMedia.length/2">\n' +
    '                                            <label>\n' +
    '                                                <input type="checkbox" value="{{ mediumTitle }}" ng-click="toggle(filters.media, mediumTitle)" ng-checked="checkFilter(filters.media, mediumTitle)"> {{ ::mediumTitle }}\n' +
    '                                            </label>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <p class="text-right">\n' +
    '                            <button type="button" ng-click="reset()" class="btn btn-danger btn-xs">Reset</button>\n' +
    '                        </p>\n' +
    '                    </div>\n' +
    '                </fieldset>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body clearfix">\n' +
    '            <form ng-action="false" class="form-inline pull-right">\n' +
    '                <label>Start</label>\n' +
    '\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="previousOne()">\n' +
    '                    <span class="glyphicon glyphicon-backward"></span>\n' +
    '                </button>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Week</label>\n' +
    '                    <input type="number" min="1" max="52" ng-model="week" ng-change="weekYearWithTimeout()" class="form-control input-sm" />\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Year</label>\n' +
    '                    <input type="number" min="2015" max="2100" ng-model="year" ng-change="weekYearWithTimeout()" class="form-control input-sm" />\n' +
    '                </div>\n' +
    '\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="nextOne()">\n' +
    '                    <span class="glyphicon glyphicon-forward"></span>\n' +
    '                </button>\n' +
    '\n' +
    '                <label>End</label>\n' +
    '\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="previousEnd()">\n' +
    '                    <span class="glyphicon glyphicon-backward"></span>\n' +
    '                </button>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Week</label>\n' +
    '                    <input type="number" min="1" max="52" ng-model="endweek" ng-change="weekYearWithTimeout()" class="form-control input-sm" />\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Year</label>\n' +
    '                    <input type="number" min="2015" max="2100" ng-model="endyear" ng-change="weekYearWithTimeout()" class="form-control input-sm" />\n' +
    '                </div>\n' +
    '\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="nextEnd()">\n' +
    '                    <span class="glyphicon glyphicon-forward"></span>\n' +
    '                </button>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="table-responsive booking-container" ng-click="jumpToDate($event)">\n' +
    '            <table class="table table-bordered" ng-repeat="location in topLocations" >\n' +
    '                <thead ng-show="filteredMedia[location.id].length > 0">\n' +
    '                    <tr>\n' +
    '                        <th class="overview" width="200" colspan="10"><h5>{{ ::location.title }}, {{ ::location.city }}</h5>\n' +
    '                        </th>\n' +
    '                    </tr>\n' +
    '                </thead>\n' +
    '\n' +
    '                <tbody ng-repeat="medium in filteredMedia[location.id]">\n' +
    '                    <tr>\n' +
    '                        <td rowspan="2" class="table-divider overview vertical-align" width="50">\n' +
    '                            <div class="image-wrapper-thumb">\n' +
    '                                <img tooltip-placement="right" tooltip-html="popupImage(medium.image.slideshow)" ng-src="{{ medium.image.thumbnail || \'http://placehold.it/40x40\' }}" width="50" />\n' +
    '                            </div>\n' +
    '                        </td>\n' +
    '                        <td rowspan="2" class="table-divider overview vertical-align" width="150">\n' +
    '                            {{ ::medium.title }}\n' +
    '                        </td>\n' +
    '                        <td\n' +
    '                            tooltip="{{ tooltips[medium.id][yearWeekEvenFormat[i]] || yearWeekEvenFormat[i] }}"\n' +
    '                            class="bg-success text-center vertical-align {{ cellClass[medium.id][yearWeekEvenFormat[i]] }}"\n' +
    '                            ng-repeat="(i, week) in yearWeekStatic"\n' +
    '                            data-date="{{ yearWeekEvenFormat[i] }}"\n' +
    '                        >\n' +
    '                            <a data-date="{{ yearWeekEvenFormat[i] }}">{{ yearWeekEvenNumbers[i] }}</a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                    <tr class="table-divider">\n' +
    '                        <td\n' +
    '                            tooltip="{{ tooltips[medium.id][yearWeekOddFormat[i]] || yearWeekOddFormat[i] }}"\n' +
    '                            class="bg-success text-center vertical-align {{ cellClass[medium.id][yearWeekOddFormat[i]] }} {{ partials[medium.id][yearWeekOddFormat[i]] }}"\n' +
    '                            ng-repeat="(i, week) in yearWeekStatic"\n' +
    '                            data-date="{{ yearWeekOddFormat[i] }}"\n' +
    '                        >\n' +
    '                            <a data-date="{{ yearWeekOddFormat[i] }}">{{ yearWeekOddNumbers[i] }}</a>\n' +
    '                        </td>\n' +
    '                    </tr>\n' +
    '                </tbody>\n' +
    '            </table>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
