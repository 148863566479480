angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_location_form.html',
    '<ul ng-show="errors.errors.length" class="text-danger">\n' +
    '    <li ng-repeat="error in errors.errors">{{ error }}</li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Title</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.title.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.title.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="location.title" required class="form-control" id="title" placeholder="Enter title">\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="streetName">Street</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.streetName.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.streetName.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="location.streetName" class="form-control" id="streetName">\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="houseNo">House Number</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.houseNo.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.houseNo.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="location.houseNo" class="form-control" id="houseNo">\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="postalCode">Postal Code</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.postalCode.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.postalCode.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="location.postalCode" class="form-control" id="postalCode">\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="city">City</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.city.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.city.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="location.city" class="form-control" id="city">\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="country">Country</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.country.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.country.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <select class="form-control" ng-model="location.country" ng-options="code as country for (code, country) in Countries">\n' +
    '        <option value=""></option>\n' +
    '    </select>\n' +
    '</div>\n' +
    '\n' +
    '<fieldset>\n' +
    '    <legend>Contacts</legend>\n' +
    '\n' +
    '    <div ng-repeat="(key, contact) in location.contacts">\n' +
    '        <div class="row" ng-include="\'_contact_form.html\'">\n' +
    '        </div>\n' +
    '\n' +
    '        <a ng-click="remove(key)" class="btn btn-danger btn-xs" tooltip="Remove Contact">\n' +
    '            <span class="glyphicon glyphicon-remove"></span>\n' +
    '        </a>\n' +
    '\n' +
    '        <hr />\n' +
    '    </div>\n' +
    '\n' +
    '    <p class="pull-right">\n' +
    '        <a class="btn btn-success btn-xs" tooltip="Add contact" ng-click="add()">\n' +
    '            <span class="glyphicon glyphicon-plus"></span>\n' +
    '        </a>\n' +
    '    </p>\n' +
    '</fieldset>\n' +
    '');
}]);
