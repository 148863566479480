angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('edit_customer.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Edit Customer</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="submit()">\n' +
    '            <div ng-include="\'_customer_form.html\'"></div>\n' +
    '\n' +
    '            <button type="submit" class="btn btn-primary">Update</button>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Change Brand</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-controller="CustomerController.brand" ng-submit="upload()" class="form-inline">\n' +
    '            <div class="row">\n' +
    '                <div class="col-md-6">\n' +
    '                    <img class="img-thumbnail" ng-src="{{ customer.brand || \'/img/nobrand.png\' }}" alt="{{ customer.title }}" />\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="col-md-6">\n' +
    '                    <ul ng-if="errors.file.length" class="text-danger">\n' +
    '                        <li ng-repeat="error in errors.file">{{ error }}</li>\n' +
    '                    </ul>\n' +
    '\n' +
    '                    <div class="form-group">\n' +
    '                        <label for="locale" class="sr-only">Brand</label>\n' +
    '\n' +
    '                        <div class="input-group">\n' +
    '                            <input style="padding:6px 0" accept="image/*" onchange="angular.element(this).scope().file = this.files[0]" type="file" class="form-control" />\n' +
    '\n' +
    '                            <span class="input-group-btn">\n' +
    '                                <button type="submit" class="btn btn-default">Upload</button>\n' +
    '                            </span>\n' +
    '                        </div>\n' +
    '                    </div>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
