angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('inactive.html',
    '<div class="container">\n' +
    '    <div class="row">\n' +
    '        <div class="col-lg-4 col-lg-push-4" style="margin-top:60px">\n' +
    '            <p>\n' +
    '                <img class="img-rounded center-block" ng-src="{{ customer.brand || \'/img/nobrand.png\' }}" alt="{{ customer.title }}" />\n' +
    '            </p>\n' +
    '\n' +
    '            <div class="panel panel-default" style="margin-top:30px">\n' +
    '                <div class="panel-heading">\n' +
    '                    <h3 class="panel-title">Inactive Customer</h3>\n' +
    '                </div>\n' +
    '\n' +
    '                <div class="panel-body">\n' +
    '                    <p>This Customer have been deactivated.</p>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
