angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('user_customer.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">User</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <table class="table table-bordered">\n' +
    '        <tbody>\n' +
    '            <tr>\n' +
    '                <th width="25%">Name</th>\n' +
    '                <td>{{ userCustomer.user.name }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Email</th>\n' +
    '                <td ng-bind-html="userCustomer.user.email | linky"></td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Phone</th>\n' +
    '                <td>{{ userCustomer.user.phoneNo }}</td>\n' +
    '            </tr>\n' +
    '        <tbody>\n' +
    '    </table>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Locations</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <table class="table table-bordered">\n' +
    '        <thead>\n' +
    '            <tr>\n' +
    '                <th>Name</th>\n' +
    '            </tr>\n' +
    '        </thead>\n' +
    '\n' +
    '        <tbody>\n' +
    '            <tr ng-repeat="location in userCustomer.locations | orderBy:\'title\'">\n' +
    '                <td>\n' +
    '                    <a ui-sref="location.show({ id : location.id })">{{ location.title }}</a>\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr ng-if="!userCustomer.locations.length">\n' +
    '                <td>No locations.</td>\n' +
    '            </tr>\n' +
    '        <tbody>\n' +
    '    </table>\n' +
    '</div>\n' +
    '');
}]);
