angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_navbar.html',
    '<nav class="navbar navbar-fixed-top navbar-primary" ng-controller="NavbarController">\n' +
    '    <div class="container">\n' +
    '        <div class="navbar-header">\n' +
    '            <button type="button" ng-click="collapsed = !collapsed" class="navbar-toggle" aria-expanded="false" aria-controls="navbar">\n' +
    '                <span class="sr-only">Toggle navigation</span>\n' +
    '                <span class="icon-bar"></span>\n' +
    '                <span class="icon-bar"></span>\n' +
    '                <span class="icon-bar"></span>\n' +
    '            </button>\n' +
    '\n' +
    '            <a class="navbar-brand" ui-sref="index">\n' +
    '                <img ng-src="{{ customer.brand || \'/img/nobrand.png\' }}" alt="{{ customer.title }}" />\n' +
    '            </a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div id="navbar"  ng-class="{ \'collapse\' : collapsed }" class="navbar-collapse navbar-right">\n' +
    '            <ul class="nav navbar-nav">\n' +
    '                <li ui-sref-active="active"><a ui-sref="dayview">Day View</a></li>\n' +
    '                <li ui-sref-active="active"><a ui-sref="index">Week View</a></li>\n' +
    '                <li ui-sref-active="active"><a ui-sref="yearview">Year View</a></li>\n' +
    '                <li ui-sref-active="active"><a ui-sref="campaign">Campaigns</a></li>\n' +
    '                <li ui-sref-active="active"><a ui-sref="location">Mediagroup</a></li>\n' +
    '                <li ui-sref-active="active"><a ui-sref="media.list">Media</a></li>\n' +
    '\n' +
    '                <li ng-if="customer|can_edit" ui-sref-active="active">\n' +
    '                    <a ui-sref="user_customer">Users</a>\n' +
    '                </li>\n' +
    '\n' +
    '                <li ui-sref-active="active">\n' +
    '                    <a ui-sref="help" tooltip="{{ \'Help\' }}" tooltip-placement="bottom">\n' +
    '                        <span class="glyphicon glyphicon-info-sign"></span>\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '\n' +
    '                <li ui-sref-active="active">\n' +
    '                    <a ui-sref="profile" tooltip="{{ \'Profile\' }}" tooltip-placement="bottom">\n' +
    '                        <span class="glyphicon glyphicon-user"></span>\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '\n' +
    '                <li ng-if="administrator" ng-class="$state.includes(\'admin\') ? \'active\' : \'\'">\n' +
    '                    <a ui-sref="customer" tooltip-placement="bottom" tooltip="{{ \'Administration\' }}" >\n' +
    '                        <span class="glyphicon glyphicon-cog"></span>\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '\n' +
    '                <li>\n' +
    '                    <a href="/logout" tooltip="{{ \'Logout\' }}" tooltip-placement="bottom">\n' +
    '                        <span class="glyphicon glyphicon-log-out" aria-hidden="true"></span>\n' +
    '                    </a>\n' +
    '                </li>\n' +
    '            </ul>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</nav>\n' +
    '');
}]);
