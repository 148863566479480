angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_media_form.html',
    '<ul ng-show="errors.errors.length" class="text-danger">\n' +
    '    <li ng-repeat="error in errors.errors">{{ error }}</li>\n' +
    '</ul>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="title">Title</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.title.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.title.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="media.title" required class="form-control" id="title" placeholder="Enter title">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label>Special</label>\n' +
    '\n' +
    '    <div class="checkbox">\n' +
    '        <label>\n' +
    '            <input type="checkbox" name="series" ng-model="media.series">\n' +
    '            <span>Series</span>\n' +
    '        </label>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group">\n' +
    '    <label for="location">Locations</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.location.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.location.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <select class="form-control" ng-model="$parent.location" ng-options="item.id as item.title for item in locations">\n' +
    '    </select>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="title">Material</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.material.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.material.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="media.material" required class="form-control" id="title" />\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="title">Material Information</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.materialInfo.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.materialInfo.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <textarea cols="5" ng-model="media.materialInfo" class="form-control"></textarea>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-4">\n' +
    '        <label for="title">Deadline</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.dealine.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.deadline.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="media.deadline" class="form-control" id="title" />\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-4">\n' +
    '        <label for="title">Delivery</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.delivery.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.delivery.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="media.delivery" class="form-control" id="title" />\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-4">\n' +
    '        <label for="title">Finalization</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.finalization.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.finalization.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="media.finalization" class="form-control" id="title" />\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-4">\n' +
    '        <label for="title">Sales price</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.worth.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.worth.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="number" ng-model="media.worth" required class="form-control" id="title" />\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-4">\n' +
    '        <label for="title">Count</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.count.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.count.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="number" ng-model="media.count" required class="form-control" id="title" />\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-4">\n' +
    '        <label for="title">Traffic</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.traffic.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.traffic.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <input type="text" ng-model="media.traffic" class="form-control" id="title" />\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="type">Type</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.type.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.type.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <select class="form-control" ng-model="media.type" ng-options="key as type for (key, type) in types">\n' +
    '            <option value=""></option>\n' +
    '        </select>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="category">Category</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.category.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.category.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <select class="form-control" ng-model="media.category" ng-options="key as category for (key, category) in categories">\n' +
    '            <option value=""></option>\n' +
    '        </select>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="row">\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="notes">Notes</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.notes.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.notes.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <textarea rows="5" ng-model="media.notes" required class="form-control" id="notes"></textarea>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="form-group col-md-6">\n' +
    '        <label for="description">Description</label>\n' +
    '\n' +
    '        <ul ng-show="errors.children.description.errors.length" class="text-danger">\n' +
    '            <li ng-repeat="error in errors.children.description.errors">{{ error }}</li>\n' +
    '        </ul>\n' +
    '\n' +
    '        <textarea rows="5" ng-model="media.description" required class="form-control" id="description"></textarea>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<fieldset>\n' +
    '    <legend>Format</legend>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="type">Width</label>\n' +
    '\n' +
    '            <ul ng-show="errors.children.measurement.children.width.errors.length" class="text-danger">\n' +
    '                <li ng-repeat="error in errors.children.measurement.children.width.errors">{{ error }}</li>\n' +
    '            </ul>\n' +
    '\n' +
    '            <input class="form-control" type="text" ng-model="media.measurement.width">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="type">Height</label>\n' +
    '\n' +
    '            <ul ng-show="errors.children.measurement.children.height.errors.length" class="text-danger">\n' +
    '                <li ng-repeat="error in errors.children.measurement.children.height.errors">{{ error }}</li>\n' +
    '            </ul>\n' +
    '\n' +
    '            <input class="form-control" type="text" ng-model="media.measurement.height">\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group col-md-4">\n' +
    '            <label for="unit">Unit</label>\n' +
    '\n' +
    '            <ul ng-show="errors.children.measurement.children.unit.errors.length" class="text-danger">\n' +
    '                <li ng-repeat="error in errors.children.measurement.children.unit.errors">{{ error }}</li>\n' +
    '            </ul>\n' +
    '\n' +
    '            <select class="form-control" ng-model="media.measurement.unit" ng-options="unit for unit in units">\n' +
    '            </select>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</fieldset>\n' +
    '\n' +
    '<fieldset>\n' +
    '    <legend>Period</legend>\n' +
    '\n' +
    '    <div class="row">\n' +
    '        <div class="form-group col-md-6">\n' +
    '            <label for="type">Start</label>\n' +
    '\n' +
    '            <ul ng-show="errors.children.period.children.start.errors.length" class="text-danger">\n' +
    '                <li ng-repeat="error in errors.children.period.children.start.errors">{{ error }}</li>\n' +
    '            </ul>\n' +
    '\n' +
    '            <input class="form-control" type="text" max-date="period.end" datepicker-popup is-open="datepickers.start" ng-model="period.start" ng-click="datepickers.start = !datepickers.start" />\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="form-group col-md-6">\n' +
    '            <label for="type">End</label>\n' +
    '\n' +
    '            <ul ng-show="errors.children.period.children.end.errors.length" class="text-danger">\n' +
    '                <li ng-repeat="error in errors.children.period.children.end.errors">{{ error }}</li>\n' +
    '            </ul>\n' +
    '\n' +
    '            <input class="form-control" type="text" min-date="period.start" datepicker-popup is-open="datepickers.end" ng-model="period.end" ng-click="datepickers.end = !datepickers.end" />\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</fieldset>\n' +
    '\n' +
    '<fieldset>\n' +
    '    <legend>Contacts</legend>\n' +
    '\n' +
    '    <div ng-repeat="(key, contact) in media.contacts">\n' +
    '        <div class="row" ng-include="\'_contact_form.html\'">\n' +
    '        </div>\n' +
    '\n' +
    '        <a ng-click="remove(key)" class="btn btn-danger btn-xs" tooltip="Remove Contact">\n' +
    '            <span class="glyphicon glyphicon-remove"></span>\n' +
    '        </a>\n' +
    '\n' +
    '        <hr />\n' +
    '    </div>\n' +
    '\n' +
    '    <p class="pull-right">\n' +
    '        <a class="btn btn-success btn-xs" tooltip="Add contact" ng-click="add()">\n' +
    '            <span class="glyphicon glyphicon-plus"></span>\n' +
    '        </a>\n' +
    '    </p>\n' +
    '</fieldset>\n' +
    '');
}]);
