angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('app.html',
    '<div ng-include="\'_navbar.html\'" class="navbar-wrapper"></div>\n' +
    '\n' +
    '<div ui-view></div>\n' +
    '\n' +
    '<div class="footer">\n' +
    '    <div class="container">\n' +
    '        <hr />\n' +
    '\n' +
    '        <div class="row">\n' +
    '            <div class="col-md-6">\n' +
    '                <p>&copy; City Media A/S</p>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="col-md-6">\n' +
    '                <ul class="list-inline pull-right">\n' +
    '                    <li>\n' +
    '                        <a href="mailto:support@camplan.dk?subject=Camplan">Contact</a>\n' +
    '                    </li>\n' +
    '\n' +
    '                    <li>\n' +
    '                        <a target="_self" href="/assets/aftalebestemmelser_camplan.pdf">Agreement conditions</a>\n' +
    '                    </li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
