angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('location.html',
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading clearfix">\n' +
    '        <h1 class="panel-title pull-left">{{ location.title }}</h1>\n' +
    '\n' +
    '        <div ng-if="customer|can_edit" class="pull-right">\n' +
    '            <a ui-sref="^.edit({ id : location.id })" class="btn btn-xs btn-info" tooltip="Edit">\n' +
    '                <span class="glyphicon glyphicon-edit"></span>\n' +
    '            </a>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <table class="table table-bordered">\n' +
    '        <tbody>\n' +
    '            <tr>\n' +
    '                <th width="25%">Title</th>\n' +
    '                <td>{{ location.title }}</td>\n' +
    '            </tr>\n' +
    '\n' +
    '            <tr>\n' +
    '                <th>Address</th>\n' +
    '                <td>\n' +
    '                    {{ location.streetName }} {{ location.houseNo }}<br />\n' +
    '                    {{ location.postalCode }} {{ location.city }}<br />\n' +
    '                    {{ location.country }}\n' +
    '                </td>\n' +
    '            </tr>\n' +
    '\n' +
    '        <tbody>\n' +
    '    </table>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h2 class="panel-title">Contacts</h2>\n' +
    '    </div>\n' +
    '\n' +
    '    <div ng-if="!location.contacts.length" class="panel-body">\n' +
    '        No contacts.\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive">\n' +
    '        <table ng-if="location.contacts.length" class="table table-striped table-bordered">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th>Name</th>\n' +
    '                    <th>Title</th>\n' +
    '                    <th>Phone</th>\n' +
    '                    <th>Email</th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="contact in location.contacts">\n' +
    '                    <td>{{ contact.name }}</td>\n' +
    '                    <td>{{ contact.title }}</td>\n' +
    '                    <td>{{ contact.phone || (\'None\') }}</td>\n' +
    '                    <td ng-bind-html="contact.email | linky"></td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '</div>\n' +
    '\n' +
    '<div class="panel panel-default">\n' +
    '    <div class="panel-heading">\n' +
    '        <h1 class="panel-title">Media</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body">\n' +
    '        <form ng-submit="false">\n' +
    '            <div class="form-group">\n' +
    '                <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                <input type="search" class="form-control" ng-model="mediaFilter" placeholder="Filter" />\n' +
    '            </div>\n' +
    '        </form>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="table-responsive">\n' +
    '        <table class="table table-striped table-bordered">\n' +
    '            <thead>\n' +
    '                <tr>\n' +
    '                    <th>Title</th>\n' +
    '                    <th>Category</th>\n' +
    '                    <th>Type</th>\n' +
    '                    <th>Format</th>\n' +
    '                    <th width="1">Series</th>\n' +
    '                </tr>\n' +
    '            </thead>\n' +
    '\n' +
    '            <tbody>\n' +
    '                <tr ng-repeat="(key, medium) in media | search:mediaFilter">\n' +
    '                    <td><a ui-sref="media.show({ id : medium.id })">{{ medium.title }}</a></td>\n' +
    '\n' +
    '                    <td>{{ medium.category | mediaCategory }}</td>\n' +
    '                    <td>{{ medium.type | mediaType }}</td>\n' +
    '                    <td>{{ medium.measurement | measurement }}</td>\n' +
    '\n' +
    '                    <td class="text-center">\n' +
    '                        <span ng-if="medium.series" class="label label-success">Yes</span>\n' +
    '                        <span ng-if="!medium.series" class="label label-danger">No</span>\n' +
    '                    </td>\n' +
    '                </tr>\n' +
    '            </tbody>\n' +
    '        </table>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="panel-body" ng-if="!media.length">\n' +
    '        No Media.\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
