angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('help.html',
    '<div class="container">\n' +
    '    <div class="navbar navbar-default navbar-condensed navbar-fixed-top navbar-secondary">\n' +
    '        <div class="container">\n' +
    '            <div class="navbar-inner">\n' +
    '                <ul class="nav navbar-nav">\n' +
    '                    <li offset="106" du-scrollspy="general"><a href="#general">General</a></li>\n' +
    '                    <li offset="106" du-scrollspy="user"><a href="#user">User</a></li>\n' +
    '                    <li offset="106" du-scrollspy="booking"><a href="#booking">Booking</a></li>\n' +
    '                    <li offset="106" du-scrollspy="media"><a href="#media">Media</a></li>\n' +
    '                </ul>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="has-secondary-navbar">\n' +
    '        <div class="panel panel-default" id="general">\n' +
    '            <div class="panel-heading">\n' +
    '                <h2 class="panel-title">General</h2>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="panel-body">\n' +
    '                <dl>\n' +
    '                    <dt>Is account registration required?</dt>\n' +
    '                    <dd>Account registration at PrepBootstrap is only required if you will be selling or buying themes. This ensures a valid communication channel for all parties involved in any transactions.</dd>\n' +
    '\n' +
    '                    <dt>Can I submit my own Bootstrap templates or themes?</dt>\n' +
    '                    <dd>A lot of the content of the site has been submitted by the community. Whether it is a commercial element/theme or a free one, you are encouraged to contribute. All credits are published along with the resources.</dd>\n' +
    '\n' +
    '                    <dt>What is the currency used for all transactions?</dt>\n' +
    '                    <dd>All prices for themes, templates and other items, including each seller\'s or buyer\'s account balance are in USD</dd>\n' +
    '                </dl>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default" id="user">\n' +
    '            <div class="panel-heading">\n' +
    '                <h2 class="panel-title">User</h2>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="panel-body">\n' +
    '                <dl>\n' +
    '                    <dt>Is account registration required?</dt>\n' +
    '                    <dd>Account registration at PrepBootstrap is only required if you will be selling or buying themes. This ensures a valid communication channel for all parties involved in any transactions.</dd>\n' +
    '\n' +
    '                    <dt>Can I submit my own Bootstrap templates or themes?</dt>\n' +
    '                    <dd>A lot of the content of the site has been submitted by the community. Whether it is a commercial element/theme or a free one, you are encouraged to contribute. All credits are published along with the resources.</dd>\n' +
    '\n' +
    '                    <dt>What is the currency used for all transactions?</dt>\n' +
    '                    <dd>All prices for themes, templates and other items, including each seller\'s or buyer\'s account balance are in USD</dd>\n' +
    '                </dl>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '\n' +
    '        <div class="panel panel-default" id="booking">\n' +
    '            <div class="panel-heading">\n' +
    '                <h2 class="panel-title">Booking</h2>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="panel-body">\n' +
    '                <dl>\n' +
    '                    <dt>Is account registration required?</dt>\n' +
    '                    <dd>Account registration at PrepBootstrap is only required if you will be selling or buying themes. This ensures a valid communication channel for all parties involved in any transactions.</dd>\n' +
    '\n' +
    '                    <dt>Can I submit my own Bootstrap templates or themes?</dt>\n' +
    '                    <dd>A lot of the content of the site has been submitted by the community. Whether it is a commercial element/theme or a free one, you are encouraged to contribute. All credits are published along with the resources.</dd>\n' +
    '\n' +
    '                    <dt>What is the currency used for all transactions?</dt>\n' +
    '                    <dd>All prices for themes, templates and other items, including each seller\'s or buyer\'s account balance are in USD</dd>\n' +
    '                </dl>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel panel-default" id="media">\n' +
    '            <div class="panel-heading">\n' +
    '                <h2 class="panel-title">Media</h2>\n' +
    '            </div>\n' +
    '\n' +
    '            <div class="panel-body">\n' +
    '                <dl>\n' +
    '                    <dt>Is account registration required?</dt>\n' +
    '                    <dd>Account registration at PrepBootstrap is only required if you will be selling or buying themes. This ensures a valid communication channel for all parties involved in any transactions.</dd>\n' +
    '\n' +
    '                    <dt>Can I submit my own Bootstrap templates or themes?</dt>\n' +
    '                    <dd>A lot of the content of the site has been submitted by the community. Whether it is a commercial element/theme or a free one, you are encouraged to contribute. All credits are published along with the resources.</dd>\n' +
    '\n' +
    '                    <dt>What is the currency used for all transactions?</dt>\n' +
    '                    <dd>All prices for themes, templates and other items, including each seller\'s or buyer\'s account balance are in USD</dd>\n' +
    '                </dl>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
