angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('_contact_form.html',
    '<div class="form-group col-md-3">\n' +
    '    <label for="title">Name</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.contacts.children[key].children.name.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.contacts.children[key].children.name.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="contact.name" class="form-control" id="title">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group col-md-3">\n' +
    '    <label for="streetName">Email</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.contacts.children[key].children.email.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.contacts.children[key].children.email.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="email" ng-model="contact.email" class="form-control" id="streetName">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group col-md-3">\n' +
    '    <label for="houseNo">Phone</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.contacts.children[key].children.phone.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.contacts.children[key].children.phone.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="contact.phone" class="form-control" id="houseNo">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group col-md-3">\n' +
    '    <label for="houseNo">Title</label>\n' +
    '\n' +
    '    <ul ng-show="errors.children.contacts.children[key].children.title.errors.length" class="text-danger">\n' +
    '        <li ng-repeat="error in errors.children.contacts.children[key].children.title.errors">{{ error }}</li>\n' +
    '    </ul>\n' +
    '\n' +
    '    <input type="text" ng-model="contact.title" class="form-control" id="houseNo">\n' +
    '</div>\n' +
    '\n' +
    '<div class="form-group col-md-12">\n' +
    '    <div class="checkbox">\n' +
    '        <label>\n' +
    '            <input type="checkbox" ng-model="contact.prioritize"> <span>Prioritize</span>\n' +
    '        </label>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
