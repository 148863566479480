angular.module('CamPlan').run(['$templateCache', function($templateCache) {
  $templateCache.put('dayview.html',
    '<div class="container-fluid">\n' +
    '    <div class="panel panel-default">\n' +
    '        <div class="panel-heading">\n' +
    '            <h1 class="panel-title">Day View</h1></a>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body">\n' +
    '            <form ng-submit="false">\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Filter</label>\n' +
    '\n' +
    '                    <input type="search" class="form-control" ng-keyup="saveSearch()" ng-model="search" placeholder="Filter" />\n' +
    '\n' +
    '                    <button class="btn btn-link inner-addon" ng-click="help()" tooltip="{{ \'Help\' }}">\n' +
    '                        <span class="glyphicon glyphicon-question-sign"></span>\n' +
    '                    </button>\n' +
    '                </div>\n' +
    '                <div style="width: 50%; margin-bottom: 10px;" ng-init="refreshData(false)">\n' +
    '                    <ui-select ng-model="campaignAssoc.campaign" search-enabled="false" on-select="onSelected($item)">\n' +
    '                        <ui-select-match placeholder="Select a Campaign">\n' +
    '                            <span ng-bind="$select.selected.title"></span>\n' +
    '                        </ui-select-match>\n' +
    '                        <ui-select-choices ng-disable="true" repeat="campaign in (campaignsSelect | filter: $select.search) track by $index">\n' +
    '                            <span ng-bind="campaign.title"></span>\n' +
    '                        </ui-select-choices>\n' +
    '                    </ui-select>\n' +
    '                </div>\n' +
    '\n' +
    '                <fieldset>\n' +
    '                    <legend>\n' +
    '                        <button ng-click="advanced = !advanced; populateCampaigns()" class="btn btn-link">Show/Hide advanced filtering</button>\n' +
    '                    </legend>\n' +
    '\n' +
    '                    <div ng-hide="!advanced" class="clearfix">\n' +
    '                        <div class="row">\n' +
    '                            <div class="form-group col-md-2">\n' +
    '                                <label>Types</label>\n' +
    '\n' +
    '                                <div class="checkbox" ng-repeat="(name, title) in TYPES" >\n' +
    '                                    <label>\n' +
    '                                        <input type="checkbox" value="{{ name }}" ng-click="toggle(filters.types, name)" ng-checked="checkFilter(filters.types, name)"> {{ ::title }}\n' +
    '                                    </label>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="form-group col-md-2">\n' +
    '                                <label>Categories</label>\n' +
    '\n' +
    '                                <div class="checkbox" ng-repeat="(name, title) in CATEGORIES" >\n' +
    '                                    <label>\n' +
    '                                        <input type="checkbox" value="{{ name }}" ng-click="toggle(filters.categories, name)" ng-checked="checkFilter(filters.categories, name)"> {{ ::title }}\n' +
    '                                    </label>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="form-group col-md-2">\n' +
    '                                <label>Locations</label>\n' +
    '                                <div class="checkbox" ng-repeat="location in locations">\n' +
    '                                    <label>\n' +
    '                                        <input type="checkbox" value="{{ location.id }}" ng-click="toggle(filters.locations, location.id)" ng-checked="checkFilter(filters.locations, location.id)"> {{ location.title }}\n' +
    '                                    </label>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '\n' +
    '                            <div class="col-md-4">\n' +
    '                                <div class="row form-group">\n' +
    '                                    <label class="col-sm-12">Media</label>\n' +
    '\n' +
    '                                    <div class="col-sm-6">\n' +
    '                                        <div class="checkbox" ng-repeat="mediumTitle in facetMedia|slice:0:facetMedia.length/2">\n' +
    '                                            <label>\n' +
    '                                                <input type="checkbox" value="{{ mediumTitle }}" ng-click="toggle(filters.media, mediumTitle)" ng-checked="checkFilter(filters.media, mediumTitle)"> {{ ::mediumTitle }}\n' +
    '                                            </label>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '\n' +
    '                                    <div class="col-sm-6">\n' +
    '                                        <div class="checkbox" ng-repeat="mediumTitle in facetMedia|slice:facetMedia.length/2">\n' +
    '                                            <label>\n' +
    '                                                <input type="checkbox" value="{{ mediumTitle }}" ng-click="toggle(filters.media, mediumTitle)" ng-checked="checkFilter(filters.media, mediumTitle)"> {{ ::mediumTitle }}\n' +
    '                                            </label>\n' +
    '                                        </div>\n' +
    '                                    </div>\n' +
    '                                </div>\n' +
    '                            </div>\n' +
    '                        </div>\n' +
    '\n' +
    '                        <p class="text-right">\n' +
    '                            <button type="button" ng-click="reset()" class="btn btn-danger btn-xs">Reset</button>\n' +
    '                        </p>\n' +
    '                    </div>\n' +
    '                </fieldset>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="panel-body clearfix">\n' +
    '            <div class="form-inline pull-right">\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="refreshData(true, week, \'click\')">\n' +
    '                    <span class="glyphicon glyphicon-refresh"></span>\n' +
    '                </button>\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="previous(week, 1)">\n' +
    '                    <span class="glyphicon glyphicon-backward"></span>\n' +
    '                </button>\n' +
    '\n' +
    '                <button class="btn btn-sm btn-default" ng-click="resetWeek()">\n' +
    '                    Today\n' +
    '                </button>\n' +
    '\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Week</label>\n' +
    '                    <input type="number" min="1" max="52" ng-model="week" ng-keypress="goToWeek($event, week, \'refrehData\')" class="form-control input-sm" />\n' +
    '                </div>\n' +
    '                <div class="form-group">\n' +
    '                    <label class="sr-only">Year</label>\n' +
    '                    <input type="number" min="2015" max="2100" ng-model="year" ng-change="weekYear()" class="form-control input-sm" />\n' +
    '                </div>\n' +
    '                <button class="btn btn-sm btn-default theme-color" ng-click="next(week, 1)">\n' +
    '                    <span class="glyphicon glyphicon-forward"></span>\n' +
    '                </button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        <div ng-show="customer|can_book" class="panel-footer clearfix panel-footer-form">\n' +
    '            <form>\n' +
    '                <label class="sr-only">Campaign</label>\n' +
    '\n' +
    '                <div class="input-group">\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <button ng-click="book(true, true)" type="submit" class="btn btn-sm btn-danger" tooltip="Book and confirm" tooltip-placement="right">\n' +
    '                            <span class="glyphicon glyphicon-check"></span>\n' +
    '                        </button>\n' +
    '                    </span>\n' +
    '                    <select ng-disabled="!campaigns.length" class="form-control input-sm" ng-model="campaign" ng-options="campaign.id as campaign.title for campaign in campaigns">\n' +
    '                        <option value="">Create Campaign</option>\n' +
    '                    </select>\n' +
    '\n' +
    '                    <span class="input-group-btn">\n' +
    '                        <button ng-click="book(false, true)" type="submit" class="btn btn-sm btn-warning" tooltip="Book">\n' +
    '                            <span class="glyphicon glyphicon-check"></span>\n' +
    '                        </button>\n' +
    '                    </span>\n' +
    '                </div>\n' +
    '            </form>\n' +
    '        </div>\n' +
    '        <section class="table-wrapper">\n' +
    '            <div class="table-responsive booking-container">\n' +
    '                <table class="table table-bordered table-striped" dw-loading="booking">\n' +
    '                    <thead>\n' +
    '                        <tr>\n' +
    '                            <th class="overview" colspan="2">\n' +
    '                                <div class="floater floater-base">Media</div>\n' +
    '                            </th>\n' +
    '                            <th class="overview" width="80">\n' +
    '                                <div class="floater">Category</div>\n' +
    '                            </th>\n' +
    '                            <th ng-repeat="day in days" class="overview text-center icons booking" width="50">\n' +
    '                                <div class="floater float-day">\n' +
    '                                    <span class="day-number" tooltip="{{ day.format(\'DD-MM-YYYY\') }}">{{ day.format(\'ddd DD-MM\') }}</span>\n' +
    '                                </div>\n' +
    '                            </th>\n' +
    '                        </tr>\n' +
    '                    </thead>\n' +
    '                     <tbody ng-repeat="location in topLocations">\n' +
    '                        <tr ng-show="filteredMedia[location.id].length > 0">\n' +
    '                            <td class="overview" colspan="16">\n' +
    '                                <a ui-sref="location.show({ id : location.id })">{{ ::location.title }}, {{ ::location.city }}</a>\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                        <tr ng-repeat="medium in filteredMedia[location.id]">\n' +
    '                            <td width="20">\n' +
    '                                <div class="image-wrapper-thumb">\n' +
    '                                    <img tooltip-placement="right" tooltip-html="popupImage(medium.image.slideshow)" ng-src="{{ ::medium.image.thumbnail || \'http://placehold.it/40x40\' }}" width="40" />\n' +
    '                                </div>\n' +
    '                            </td>\n' +
    '                            <td class="overview" width="100">\n' +
    '                                <a class="booking-media-cell" ui-sref="media.show({ id : medium.id })">\n' +
    '                                    {{ ::medium.title }}, {{ ::medium.type | mediaType }}\n' +
    '                                </a>\n' +
    '                            </td>\n' +
    '                            <td class="overview">\n' +
    '                                {{ ::medium.category | mediaCategory }}\n' +
    '                            </td>\n' +
    '                            <td\n' +
    '                                class="bg-success text-center vertical-align booking"\n' +
    '                                ng-class="(isSelected(medium, days[i]) ? \'bg-info\' : dayCellClass[medium.id][daysFormat[i]])"\n' +
    '                                ng-repeat="(i, dayStatic) in daysStatic"\n' +
    '                                ng-click="select(medium, days[i])"\n' +
    '                                ng-dblclick="instantBook(medium, days[i], true)"\n' +
    '                                data-tooltip="{{ dayTooltips[medium.id][daysFormat[i]] || daysFormat[i] }}"\n' +
    '                            >\n' +
    '                                <!--{{medium}}-->\n' +
    '                                <label class="hidden">\n' +
    '                                    <input ng-checked="isSelected(medium, days[i])" type="checkbox" value="{{ days[i].format(\'YYYY-MM-DD\') }}">\n' +
    '                                    <span class="hidden"></span>\n' +
    '                                </label>\n' +
    '\n' +
    '                                <div ng-bind-html="dayBookingLabels(medium.id, daysFormat[i])"></div>\n' +
    '\n' +
    '                            </td>\n' +
    '                        </tr>\n' +
    '                    </tbody>\n' +
    '                </table>\n' +
    '            </div>\n' +
    '        </section>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
